import Vue from "vue";
import { Module } from "vuex";

import { api } from "@/util/axios";
import { ClientEmail, Page } from "@/types";

type ClientEmailState = {
  clientEmailPage: Page<ClientEmail>;
};

const clientEmail: Module<ClientEmailState, unknown> = {
  namespaced: true,
  state: () => ({
    clientEmailPage: {
      docs: [],
      limit: 0,
      page: 0,
      pages: 0,
      sort: "",
      total: 0,
    },
  }),
  getters: {
    getClientEmail: (state) => (ClientEmailId: string) =>
      state.clientEmailPage.docs.find((c) => c._id === ClientEmailId),
    clientEmailPage: (state) => state.clientEmailPage,
  },
  mutations: {
    ADD_CLIENT_EMAIL: (state, _ClientEmail) => {
      let idx = -1;
      state.clientEmailPage.docs.map((r, i) => {
        if (r._id === _ClientEmail._id) idx = i;
      });
      if (idx === -1) state.clientEmailPage.docs.push(_ClientEmail);
      else Vue.set(state.clientEmailPage.docs, idx, _ClientEmail);
    },
    SET_CLIENT_EMAIL_PAGE: (state, list) => {
      state.clientEmailPage = list;
    },
    REMOVE_CLIENT_EMAIL(state, clientEmail) {
      let idx = -1;
      state.clientEmailPage.docs.map((r, i) => {
        if (r._id === clientEmail._id) idx = i;
      });
      if (idx > -1) state.clientEmailPage.docs.splice(idx, 1);
    },
  },
  actions: {
    fetchClientEmail(context, params = "") {
      api
        .get(`/v1/client-email${params}`)
        .then((response) => {
          context.commit("ADD_CLIENT_EMAIL", response.data.clientEmail);
        })
        .catch((error) => {
          context.dispatch(
            "setToast",
            {
              title: "Request failed!",
              type: "error",
              text: error.response?.data?.error?.message,
            },
            { root: true }
          );
        });
    },
    fetchClientEmailList(context, params = "") {
      api
        .get(`/v1/client-email${params}`)
        .then((response) => {
          context.commit(
            "SET_CLIENT_EMAIL_PAGE",
            response.data.clientEmailPage
          );
        })
        .catch((error) => {
          context.dispatch(
            "setToast",
            {
              title: "Request failed!",
              type: "error",
              text: error.response?.data?.error?.message,
            },
            { root: true }
          );
        });
    },
    async createClientEmail(context, payload) {
      return await api
        .post<{ clientEmails: ClientEmail[] }>(`/v1/client-email`, payload)
        .then((response) => {
          response.data.clientEmails.map((email) =>
            context.commit("ADD_CLIENT_EMAIL", email)
          );
          context.dispatch(
            "setToast",
            {
              title: "Success",
              type: "success",
              text: "Email(s) sent",
            },
            { root: true }
          );
          return response.data.clientEmails;
        })
        .catch((error) => {
          context.dispatch(
            "setToast",
            {
              title: "Request failed!",
              type: "error",
              text: error.response?.data?.error?.message,
            },
            { root: true }
          );
        });
    },
    deleteClientEmail(context, id) {
      api
        .delete(`/v1/client-email${id}`)
        .then((response) => {
          context.commit("REMOVE_CLIENT_EMAIL", response.data.clientEmail);
          context.dispatch(
            "setToast",
            {
              title: "Success",
              type: "success",
              text: "Client Email deleted",
            },
            { root: true }
          );
        })
        .catch((error) => {
          context.dispatch(
            "setToast",
            {
              title: "Request failed!",
              type: "error",
              text: error.response?.data?.error?.message,
            },
            { root: true }
          );
        });
    },
  },
};

export default clientEmail;

import Vue from "vue";
import { Module } from "vuex";

import { api } from "@/util/axios";
import { ClientSMS, Page } from "@/types";

type ClientSMSState = {
  clientSMSPage: Page<ClientSMS>;
};

const clientSMS: Module<ClientSMSState, unknown> = {
  namespaced: true,
  state: () => ({
    clientSMSPage: {
      docs: [],
      limit: 0,
      page: 0,
      pages: 0,
      sort: "",
      total: 0,
    },
  }),
  getters: {
    getClientSMS: (state) => (ClientSMSId: string) =>
      state.clientSMSPage.docs.find((c) => c._id === ClientSMSId),
    clientSMSPage: (state) => state.clientSMSPage,
  },
  mutations: {
    ADD_CLIENT_SMS: (state, _ClientSMS) => {
      let idx = -1;
      state.clientSMSPage.docs.map((r, i) => {
        if (r._id === _ClientSMS._id) idx = i;
      });
      if (idx === -1) state.clientSMSPage.docs.push(_ClientSMS);
      else Vue.set(state.clientSMSPage.docs, idx, _ClientSMS);
    },
    SET_CLIENT_SMS_PAGE: (state, list) => {
      state.clientSMSPage = list;
    },
    REMOVE_CLIENT_SMS(state, clientSMS) {
      let idx = -1;
      state.clientSMSPage.docs.map((r, i) => {
        if (r._id === clientSMS._id) idx = i;
      });
      if (idx > -1) state.clientSMSPage.docs.splice(idx, 1);
    },
  },
  actions: {
    fetchClientSMS(context, params = "") {
      api
        .get(`/v1/client-sms${params}`)
        .then((response) => {
          context.commit("ADD_CLIENT_SMS", response.data.clientSMS);
        })
        .catch((error) => {
          context.dispatch(
            "setToast",
            {
              title: "Request failed!",
              type: "error",
              text: error.response?.data?.error?.message,
            },
            { root: true }
          );
        });
    },
    fetchClientSMSList(context, params = "") {
      api
        .get(`/v1/client-sms${params}`)
        .then((response) => {
          context.commit("SET_CLIENT_SMS_PAGE", response.data.clientSMSPage);
        })
        .catch((error) => {
          context.dispatch(
            "setToast",
            {
              title: "Request failed!",
              type: "error",
              text: error.response?.data?.error?.message,
            },
            { root: true }
          );
        });
    },
    async createClientSMS(context, payload) {
      return await api
        .post<{ clientSMSs: ClientSMS[] }>(`/v1/client-sms`, payload)
        .then((response) => {
          response.data.clientSMSs.map((sms) =>
            context.commit("ADD_CLIENT_SMS", sms)
          );
          context.dispatch(
            "setToast",
            {
              title: "Success",
              type: "success",
              text: "SMS(s) sent",
            },
            { root: true }
          );
          return response.data.clientSMSs;
        })
        .catch((error) => {
          context.dispatch(
            "setToast",
            {
              title: "Request failed!",
              type: "error",
              text: error.response?.data?.error?.message,
            },
            { root: true }
          );
        });
    },
    deleteClientSMS(context, id) {
      api
        .delete(`/v1/client-sms${id}`)
        .then((response) => {
          context.commit("REMOVE_CLIENT_SMS", response.data.clientSMS);
          context.dispatch(
            "setToast",
            {
              title: "Success",
              type: "success",
              text: "Client SMS deleted",
            },
            { root: true }
          );
        })
        .catch((error) => {
          context.dispatch(
            "setToast",
            {
              title: "Request failed!",
              type: "error",
              text: error.response?.data?.error?.message,
            },
            { root: true }
          );
        });
    },
  },
};

export default clientSMS;

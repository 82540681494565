import Vue from "vue";
import { Module } from "vuex";

import { api } from "@/util/axios";
import { Business, Page } from "@/types";

type BusinessState = {
  businessPage: Page<Business>;
};

const business: Module<BusinessState, unknown> = {
  namespaced: true,
  state: () => ({
    businessPage: {
      docs: [],
      limit: 0,
      page: 0,
      pages: 0,
      sort: "",
      total: 0,
    },
  }),
  getters: {
    getBusiness: (state) => (businessId: string) =>
      state.businessPage.docs.find((c) => c._id === businessId),
    businessPage: (state) => state.businessPage,
  },
  mutations: {
    ADD_BUSINESS: (state, _business) => {
      let idx = -1;
      state.businessPage.docs.map((b, i) => {
        if (b._id === _business._id) idx = i;
      });
      if (idx === -1) state.businessPage.docs.push(_business);
      else Vue.set(state.businessPage.docs, idx, _business);
    },
    SET_BUSINESS_PAGE: (state, list) => {
      state.businessPage = list;
    },
    REMOVE_BUSINESS(state, business) {
      let idx = -1;
      state.businessPage.docs.map((b, i) => {
        if (b._id === business._id) idx = i;
      });
      if (idx > -1) state.businessPage.docs.splice(idx, 1);
    },
  },
  actions: {
    async fetchBusiness(context, params = "") {
      return await api
        .get(`/v1/business${params}`)
        .then((response) => {
          context.commit("ADD_BUSINESS", response.data.business);
          return response.data.business;
        })
        .catch((error) => {
          context.dispatch(
            "setToast",
            {
              title: "Request failed!",
              type: "error",
              text: error.response?.data?.error?.message,
            },
            { root: true }
          );
        });
    },
    fetchBusinessList(context, params = "") {
      api
        .get(`/v1/business${params}`)
        .then((response) => {
          context.commit("SET_BUSINESS_PAGE", response.data.businessPage);
        })
        .catch((error) => {
          context.dispatch(
            "setToast",
            {
              title: "Request failed!",
              type: "error",
              text: error.response?.data?.error?.message,
            },
            { root: true }
          );
        });
    },
    async createBusiness(context, payload) {
      return await api
        .post(`/v1/business`, payload)
        .then((response) => {
          context.commit("ADD_BUSINESS", response.data.business);
          if (response.data.role)
            context.commit("ROLE_LIST/ADD_ROLE", response.data.role, {
              root: true,
            });
          context.dispatch(
            "setToast",
            {
              title: "Success",
              type: "success",
              text: "Business created",
            },
            { root: true }
          );
          return response.data.business;
        })
        .catch((error) => {
          let message = error.response?.data?.error?.message as string;
          if (message.includes("E11000 duplicate key error collection"))
            message = "Business name already in use";
          context.dispatch(
            "setToast",
            {
              title: "Request failed!",
              type: "error",
              text: message,
            },
            { root: true }
          );
        });
    },
    async updateBusiness(context, data: { id: string; business: Business }) {
      return await api
        .put(`/v1/business/${data.id}`, data.business)
        .then((response) => {
          context.commit("ADD_BUSINESS", response.data.business);
          // context.dispatch(
          //   "setToast",
          //   {
          //     title: "Success",
          //     type: "success",
          //     text: "Business updated",
          //   },
          //   { root: true }
          // );
          return response.data.business;
        })
        .catch((error) => {
          context.dispatch(
            "setToast",
            {
              title: "Request failed!",
              type: "error",
              text: error.response?.data?.error?.message,
            },
            { root: true }
          );
        });
    },
    deleteBusiness(context, id) {
      api
        .delete(`/v1/business${id}`)
        .then((response) => {
          context.commit("REMOVE_BUSINESS", response.data.business);
          context.dispatch(
            "setToast",
            {
              title: "Success",
              type: "success",
              text: "Business deleted",
            },
            { root: true }
          );
        })
        .catch((error) => {
          context.dispatch(
            "setToast",
            {
              title: "Request failed!",
              type: "error",
              text: error.response?.data?.error?.message,
            },
            { root: true }
          );
        });
    },
  },
};

export default business;

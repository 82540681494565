import Vue from "vue";
import { Module } from "vuex";

import { api } from "@/util/axios";
import { Employee, Page } from "@/types";

type EmployeeState = {
  employeePage: Page<Employee>;
};

const employee: Module<EmployeeState, unknown> = {
  namespaced: true,
  state: () => ({
    employeePage: {
      docs: [],
      limit: 0,
      page: 0,
      pages: 0,
      sort: "",
      total: 0,
    },
  }),
  getters: {
    getEmployee: (state) => (employeeId: string) =>
      state.employeePage.docs.find((c) => c._id === employeeId),
    employeePage: (state) => state.employeePage,
  },
  mutations: {
    ADD_EMPLOYEE: (state, _employee) => {
      let idx = -1;
      state.employeePage.docs.map((a, i) => {
        if (a._id === _employee._id) idx = i;
      });
      if (idx === -1) state.employeePage.docs.push(_employee);
      else Vue.set(state.employeePage.docs, idx, _employee);
      state.employeePage.limit = state.employeePage.limit || 10;
      state.employeePage.page = state.employeePage.page || 1;
      state.employeePage.pages = state.employeePage.pages || 1;
      state.employeePage.total = state.employeePage.total || 1;
    },
    SET_EMPLOYEE_PAGE: (state, list) => {
      state.employeePage = list;
    },
    REMOVE_EMPLOYEE: (state, employee) => {
      const index = state.employeePage.docs.findIndex(
        (item) => item._id === employee._id
      );
      state.employeePage.docs.splice(index, 1);
    },
  },
  actions: {
    async fetchEmployee(context, params = "") {
      return await api
        .get(`/v1/employee${params}`)
        .then((response) => {
          context.commit("ADD_EMPLOYEE", response.data.employee);
          return response.data.employee;
        })
        .catch((error) => {
          context.dispatch(
            "setToast",
            {
              title: "Request failed!",
              type: "error",
              text: error.response?.data?.error?.message,
            },
            { root: true }
          );
        });
    },
    async fetchEmployeeList(context, params = "") {
      return await api
        .get(`/v1/employee${params}`)
        .then((response) => {
          context.commit("SET_EMPLOYEE_PAGE", response.data.employeePage);
        })
        .catch((error) => {
          context.dispatch(
            "setToast",
            {
              title: "Request failed!",
              type: "error",
              text: error.response?.data?.error?.message,
            },
            { root: true }
          );
        });
    },
    async createEmployee(context, payload) {
      return await api
        .post(`/v1/employee`, payload, { timeout: 20000 }) // Uploading some images could take time!
        .then((response) => {
          context.commit("ADD_EMPLOYEE", response.data.employee);
          context.dispatch(
            "setToast",
            {
              title: "Success",
              type: "success",
              text: "Staff created",
            },
            { root: true }
          );
          return response.data.employee;
        })
        .catch((error) => {
          context.dispatch(
            "setToast",
            {
              title: "Request failed!",
              type: "error",
              text: error.response?.data?.error?.message,
            },
            { root: true }
          );
        });
    },
    async updateEmployee(context, data: { id: string; employee: Employee }) {
      return await api
        .put(`/v1/employee/${data.id}`, data.employee)
        .then((response) => {
          context.commit("ADD_EMPLOYEE", response.data.employee);
          context.dispatch(
            "setToast",
            {
              title: "Success",
              type: "success",
              text: "Staff updated",
            },
            { root: true }
          );
          return response.data.employee;
        })
        .catch((error) => {
          context.dispatch(
            "setToast",
            {
              title: "Request failed!",
              type: "error",
              text: error.response?.data?.error?.message,
            },
            { root: true }
          );
        });
    },
    async exportEmployees(context, params = "") {
      return await api
        .get(`/v1/employee/export${params}`)
        .then((response) => response)
        .catch((error) => {
          context.dispatch(
            "setToast",
            {
              title: "Request failed!",
              type: "error",
              text: error.response?.data?.error?.message,
            },
            { root: true }
          );
        });
    },
    async getWithAvailability(context, params = "") {
      return await api
        .get(`/v1/employee/get-with-availability${params}`)
        .then((response) => {
          context.commit("SET_EMPLOYEE_PAGE", {
            docs: response.data.employees,
          });
          return response.data.employees;
        })
        .catch((error) => {
          context.dispatch(
            "setToast",
            {
              title: "Request failed!",
              type: "error",
              text: error.response?.data?.error?.message,
            },
            { root: true }
          );
        });
    },
    async deleteEmployee(context, id) {
      return await api
        .delete(`/v1/employee/${id}`, {
          headers: { "vendor-module": "employees.All Employees" },
        })
        .then((response) => {
          context.commit("REMOVE_EMPLOYEE", response.data.employee);
          context.dispatch(
            "setToast",
            {
              title: "Success",
              type: "success",
              text: "Employee deleted",
            },
            { root: true }
          );
          return response.data.employee;
        })
        .catch((error) => {
          context.dispatch(
            "setToast",
            {
              title: "Request failed!",
              type: "error",
              text: error.response?.data?.error?.message,
            },
            { root: true }
          );
        });
    },
  },
};

export default employee;













































































import Vue, { PropType } from "vue";

import { Business, Client, Role } from "@/types";
import clientEmailStoreModule from "@/store/modules/clientEmail";
import { createNamespacedHelpers } from "vuex";
import ClientEmailForm from "@/components/client/ClientEmailForm.vue";
import ActionButtons from "@/components/ActionButtons.vue";
import ViewEmail from "@/components/client/ViewEmail.vue";

const { mapGetters: emailGetters, mapActions: emailActions } =
  createNamespacedHelpers("CLIENT_EMAIL_LIST");

const VUE_APP_API_URL = process.env.VUE_APP_API_URL;

export default Vue.extend<any, any, any, any>({
  components: { ClientEmailForm, ActionButtons, ViewEmail },
  name: "ClientEmails",
  props: {
    client: {
      type: Object as PropType<Client>,
      required: false,
    },
    showActions: {
      type: Boolean,
      default: true,
    },
  },
  data: () => ({
    headers: [
      {
        text: "Client",
        align: "start",
        sortable: true,
        value: "client",
      },
      {
        text: "Subject",
        value: "subject",
      },
      { text: "Date Sent", value: "createdAt" },
      { text: "Action", value: "action" },
    ],
    addEmailDialog: false,
    email: undefined,
    showEmailDialog: false,
    options: {} as { page: number },
    apiUrl: VUE_APP_API_URL,
  }),
  computed: {
    ...emailGetters(["clientEmailPage"]),
    role(): Role {
      return this.$store.getters.role;
    },
  },
  watch: {
    role: "fetchEmails",
    options: {
      handler() {
        this.fetchEmails();
      },
      deep: true,
    },
  },
  methods: {
    ...emailActions(["fetchClientEmailList"]),
    fetchEmails() {
      if (this.role) {
        const bid = (this.role.business as Business)._id;
        const param = this.client ? `&clientId=${this.client._id}` : "";
        this.fetchClientEmailList(
          `?businessId=${bid}&page=${this.options.page || 1}${param}`
        );
      }
    },
    closeModal() {
      this.fetchEmails();
      this.addEmailDialog = false;
    },
  },
  beforeCreate() {
    if (!this.$store.hasModule("CLIENT_EMAIL_LIST")) {
      this.$store.registerModule("CLIENT_EMAIL_LIST", clientEmailStoreModule);
    }
  },
  beforeDestroy() {
    this.$store.unregisterModule("CLIENT_EMAIL_LIST");
  },
});

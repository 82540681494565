/* eslint-disable @typescript-eslint/no-explicit-any */
import { InventoryProduct, Page } from "@/types";
import { api } from "@/util/axios";
import { Module } from "vuex";

type ProductState = {
  productPage: Page<InventoryProduct>;
  skus: any[];
};

const productInventory: Module<ProductState, unknown> = {
  namespaced: true,
  state: () => ({
    productPage: {
      docs: [],
      limit: 0,
      page: 0,
      pages: 0,
      sort: "",
      total: 0,
    },
    skus: [],
  }),
  mutations: {
    ADD_INVENTORY_PRODUCT: (state, _product) => {
      if (
        state.productPage.docs.filter((item: any) => item._id === _product._id)
          .length > 0
      ) {
        state.productPage.docs = state.productPage.docs.map((item: any) =>
          item._id === _product._id ? _product : item
        );
      } else {
        state.productPage.docs.push(_product);
      }
    },
    SET_INVENTORY_PRODUCTList: (state, _products) => {
      state.productPage = _products;
    },
    REMOVE_INVENTORY_PRODUCT: (state, _product) => {
      state.productPage.docs = state.productPage.docs.filter(
        (item) => item._id !== _product._id
      );
    },
    ADD_INVENTORY_SKUS: (state, _skus) => {
      state.skus = _skus;
    },
  },
  getters: {
    productPage: (state) => state.productPage,
    getProduct: (state) => (productId: string) =>
      state.productPage.docs.find((p) => p._id === productId),
    skus: (state) => state.skus,
  },
  actions: {
    async fetchProductSkus(context, params = "") {
      return await api
        .get(`/v1/product/skus${params}`)
        .then((response) => {
          context.commit("ADD_INVENTORY_SKUS", response.data.skus);
          return response.data.skus;
        })
        .catch((error) => {
          context.dispatch(
            "setToast",
            {
              title: "Request failed!",
              type: "error",
              text: error.response?.data?.error?.message,
            },
            { root: true }
          );
        });
    },
    async fetchProduct(context, params = "") {
      return await api
        .get(`/v1/product${params}`)
        .then((response) => {
          context.commit("ADD_INVENTORY_PRODUCT", response.data.product);
          return response.data.product;
        })
        .catch((error) => {
          context.dispatch(
            "setToast",
            {
              title: "Request failed!",
              type: "error",
              text: error.response?.data?.error?.message,
            },
            { root: true }
          );
        });
    },
    async createProduct(context, payload) {
      return await api
        .post("/v1/product", payload)
        .then((response) => {
          context.commit("ADD_INVENTORY_PRODUCT", response.data.result);
          context.dispatch(
            "setToast",
            {
              title: "Success",
              type: "success",
              text: "Product Created",
            },
            { root: true }
          );
          return response.data.result;
        })
        .catch((error) => {
          context.dispatch(
            "setToast",
            {
              title: "Request failed!",
              type: "error",
              text: error.response?.data?.error?.message,
            },
            { root: true }
          );
        });
    },
    async fetchProductList(context, params = "") {
      return await api
        .get(`/v1/product${params}`)
        .then((response) => {
          context.commit(
            "SET_INVENTORY_PRODUCTList",
            response.data.productPage
          );
          return response.data;
        })
        .catch((error) => {
          context.dispatch(
            "setToast",
            {
              title: "Request failed!",
              type: "error",
              text: error.response?.data?.error?.message,
            },
            { root: true }
          );
        });
    },
    async dropProduct(context, id) {
      return await api
        .delete(`/v1/product/${id}`, {
          headers: {
            "vendor-module": "products.Product",
          },
        })
        .then((response) => {
          context.commit("REMOVE_INVENTORY_PRODUCT", response.data.product);
          context.dispatch(
            "setToast",
            {
              title: "Success",
              type: "success",
              text: "Product deleted",
            },
            { root: true }
          );
        })
        .catch((error) => {
          context.dispatch(
            "setToast",
            {
              title: "Request failed!",
              type: "error",
              text: error.response?.data?.error?.message,
            },
            { root: true }
          );
        });
    },
    async updateProduct(context, payload: { id: string; product: any }) {
      return await api
        .put(`/v1/product/${payload.id}`, { ...payload.product })
        .then((response) => {
          context.commit("ADD_INVENTORY_PRODUCT", response.data.product);
          context.dispatch(
            "setToast",
            {
              title: "Success",
              type: "success",
              text: "Product Updated!",
            },
            { root: true }
          );
        })
        .catch((error) => {
          context.dispatch(
            "setToast",
            {
              title: "Request failed!",
              type: "error",
              text: error.response?.data?.error?.message,
            },
            { root: true }
          );
        });
    },

    async updateImage(context, payload: { id: string; data: any }) {
      return await api
        .put(`/v1/product/images/${payload.id}`, payload.data)
        .then((response) => {
          context.commit("ADD_INVENTORY_PRODUCT", response.data.product);
          context.dispatch(
            "setToast",
            {
              title: "Success",
              type: "success",
              text: "Product Images Updated!",
            },
            { root: true }
          );
        })
        .catch((error) => {
          context.dispatch(
            "setToast",
            {
              title: "Request failed!",
              type: "error",
              text: error.response?.data?.error?.message,
            },
            { root: true }
          );
        });
    },

    async updateVariant(context, payload: { id: string }) {
      const { id, ...variant } = payload;
      return await api
        .put(`/v1/product/variant/${id}`, { ...variant })
        .then(() => {
          context.dispatch(
            "setToast",
            {
              title: "Success",
              type: "success",
              text: "Product Variant Updated!",
            },
            { root: true }
          );
        })
        .catch((error) => {
          context.dispatch(
            "setToast",
            {
              title: "Request failed!",
              type: "error",
              text: error.response?.data?.error?.message,
            },
            { root: true }
          );
        });
    },

    async updateVariantImage(context, payload: { id: string; data: any }) {
      return await api
        .put(`/v1/product/variant/images/${payload.id}`, payload.data)
        .then(() => {
          context.dispatch(
            "setToast",
            {
              title: "Success",
              type: "success",
              text: "Product Images Updated!",
            },
            { root: true }
          );
        })
        .catch((error) => {
          context.dispatch(
            "setToast",
            {
              title: "Request failed!",
              type: "error",
              text: error.response?.data?.error?.message,
            },
            { root: true }
          );
        });
    },
    async exportInventory(context, params = "") {
      return await api
        .get(`/v1/product/export${params}`)
        .then((response) => response)
        .catch((error) => {
          context.dispatch(
            "setToast",
            {
              title: "Request failed!",
              type: "error",
              text: error.response?.data?.error?.message,
            },
            { root: true }
          );
        });
    },
    async reviewProduct(context, payload) {
      return await api
        .post(`/v1/product/${payload.id}/review`, {
          comment: payload.comment,
          rating: 5,
        })
        .then((response) => {
          console.log(response);
        })
        .catch((error) => {
          console.log(error.response);
        });
    },
  },
};

export default productInventory;







































































































































































































import Vue from "vue";

import AppointmentForm from "@/components/AppointmentForm.vue";
import ClientForm from "@/components/ClientForm.vue";
import ClientEmailForm from "./client/ClientEmailForm.vue";
import CheckoutForm from "@/components/checkout/CheckoutStepper.vue";
import ClientSmsForm from "./client/ClientSMSForm.vue";
import { Business, Role } from "@/types";
import businessStoreModule from "@/store/modules/business";
import { createNamespacedHelpers } from "vuex";

const { mapActions: businessActions } =
  createNamespacedHelpers("BUSINESS_DETAIL");

export default Vue.extend<any, any, any, any>({
  name: "ActionButtons",
  components: {
    AppointmentForm,
    ClientForm,
    CheckoutForm,
    ClientEmailForm,
    ClientSmsForm,
  },
  props: {
    showCalendarLink: {
      type: Boolean,
      default: true,
    },
  },
  data: () => ({
    showAppointmentDialog: false,
    showCheckoutDialog: false,
    showClientDialog: false,
    contactClientDialog: false,
    appointmentGapDialog: false,
    gap: null,
  }),
  computed: {
    role(): Role {
      return this.$store.getters.role;
    },
  },
  methods: {
    ...businessActions(["updateBusiness"]),
    newAppointment() {
      this.showAppointmentDialog = true;
    },
    saveBusiness() {
      const id = (this.role.business as Business)._id;
      this.updateBusiness({
        id,
        business: { appointmentGapInMinutes: this.gap },
      }).then((business) => {
        if (business) {
          this.$store.dispatch(
            "setToast",
            {
              title: "Success",
              type: "success",
              text: "Business updated",
            },
            { root: true }
          );
          this.appointmentGapDialog = false;
        }
      });
    },
    doCheckout() {
      this.$store.dispatch("cart/deleteCart");
      this.showCheckoutDialog = true;
    },
  },
  beforeCreate() {
    if (!this.$store.hasModule("BUSINESS_DETAIL"))
      this.$store.registerModule("BUSINESS_DETAIL", businessStoreModule);
  },
  beforeDestroy() {
    this.$store.unregisterModule("BUSINESS_DETAIL");
  },
});






































































import Vue, { PropType } from "vue";
import { createNamespacedHelpers } from "vuex";

import clientEmailStoreModule from "@/store/modules/clientEmail";
import clientStoreModule from "@/store/modules/client";
import { Business, Client, Role } from "@/types";

const { mapActions: clientActions, mapGetters: clientGetters } =
  createNamespacedHelpers("CLIENT_EMAILS");

const { mapActions: emailActions } =
  createNamespacedHelpers("CLIENT_EMAIL_FORM");

export default Vue.extend<any, any, any, any>({
  name: "ClientEmailForm",
  props: {
    client: {
      type: Object as PropType<Client>,
      required: false,
    },
  },
  data() {
    return {
      valid: false,
      clientIds: [] as string[],
      subject: "",
      // title: "",
      intro: "",
      introRules: [(v: string) => !!v || "Body field is required"],
      subjectRules: [(v: string) => !!v || "Subject field is required"],
      clientRules: [
        (v: string) => !!(v && v.length) || "Client email field is required",
      ],
      titleRules: [(v: string) => !!v || "Title field is required"],
    };
  },
  mounted() {
    this.fetchData();
    this.resetForm();
  },
  computed: {
    ...clientGetters(["clientPage"]),
    role(): Role {
      return this.$store.getters.role;
    },
  },
  methods: {
    ...emailActions(["createClientEmail"]),
    ...clientActions(["fetchClientList"]),
    validateForm() {
      const valid = (
        this.$refs.emailForm as Element & {
          validate: () => boolean;
        }
      )?.validate();
      if (!valid) return;
      // Logic for sending emails

      this.createClientEmail({
        intro: this.intro,
        subject: this.subject,
        // title: this.title,
        clientIds: this.clientIds,
      }).then((emails) => {
        if (emails) this.$emit("data-saved", true);
      });
    },
    resetForm() {
      // this.title = "";
      this.subject = "";
      this.clientIds = [];
      if (this.client) this.clientIds.push(this.client._id);
      this.intro = "";
      (
        this.$refs.emailForm as Element & {
          resetValidation: () => void;
        }
      )?.resetValidation();
    },
    cancel() {
      this.$emit("cancel", true);
    },
    fetchData() {
      if (this.role) {
        const bid = (this.role.business as Business)._id;
        this.fetchClientList(`?businessId=${bid}`);
      }
    },
    searchClient(q: string) {
      if (this.role) {
        const bid = (this.role.business as Business)._id;
        if (q) this.fetchClientList(`?businessId=${bid}&q=${q}`); //TODO: use debounce
      }
    },
    addClient() {
      this.$emit("add-client", true);
    },
  },
  beforeCreate() {
    if (!this.$store.hasModule("CLIENT_EMAIL_FORM")) {
      this.$store.registerModule("CLIENT_EMAIL_FORM", clientEmailStoreModule);
    }
    if (!this.$store.hasModule("CLIENT_EMAILS")) {
      this.$store.registerModule("CLIENT_EMAILS", clientStoreModule);
    }
  },
  beforeDestroy() {
    this.$store.unregisterModule("CLIENT_EMAIL_FORM");
  },
});

import Vue from "vue";
import { Module } from "vuex";

import { api } from "@/util/axios";
import { ServicePackage, Page } from "@/types";

type ServicePackageState = {
  servicePackagePage: Page<ServicePackage>;
};

const servicePackage: Module<ServicePackageState, unknown> = {
  namespaced: true,
  state: () => ({
    servicePackagePage: {
      docs: [],
      limit: 0,
      page: 0,
      pages: 0,
      sort: "",
      total: 0,
    },
  }),
  getters: {
    getServicePackage: (state) => (servicePackageId: string) =>
      state.servicePackagePage.docs.find((c) => c._id === servicePackageId),
    servicePackagePage: (state) => state.servicePackagePage,
  },
  mutations: {
    ADD_SERVICE_PACKAGE: (state, _servicePackage) => {
      let idx = -1;
      state.servicePackagePage.docs.map((a, i) => {
        if (a._id === _servicePackage._id) idx = i;
      });
      if (idx === -1) state.servicePackagePage.docs.push(_servicePackage);
      else Vue.set(state.servicePackagePage.docs, idx, _servicePackage);
    },
    SET_SERVICE_PACKAGE_PAGE: (state, list) => {
      state.servicePackagePage = list;
    },
    REMOVE_SERVICE_PACKAGE(state, servicePackage) {
      let idx = -1;
      state.servicePackagePage.docs.map((a, i) => {
        if (a._id === servicePackage._id) idx = i;
      });
      if (idx > -1) state.servicePackagePage.docs.splice(idx, 1);
    },
  },
  actions: {
    fetchServicePackage(context, params = "") {
      api
        .get(`/v1/service-package${params}`)
        .then((response) => {
          context.commit("ADD_SERVICE_PACKAGE", response.data.servicePackage);
        })
        .catch((error) => {
          context.dispatch(
            "setToast",
            {
              title: "Request failed!",
              type: "error",
              text: error.response?.data?.error?.message,
            },
            { root: true }
          );
        });
    },
    async fetchServicePackageList(context, params = "") {
      return await api
        .get(`/v1/service-package${params}`)
        .then((response) => {
          context.commit(
            "SET_SERVICE_PACKAGE_PAGE",
            response.data.servicePackagePage
          );

          return response.data.servicePackagePage;
        })
        .catch((error) => {
          context.dispatch(
            "setToast",
            {
              title: "Request failed!",
              type: "error",
              text: error.response?.data?.error?.message,
            },
            { root: true }
          );
        });
    },
    async createServicePackage(context, payload) {
      return await api
        .post(`/v1/service-package`, payload)
        .then((response) => {
          context.commit("ADD_SERVICE_PACKAGE", response.data.servicePackage);
          context.dispatch(
            "setToast",
            {
              title: "Success",
              type: "success",
              text: "ServicePackage created",
            },
            { root: true }
          );
          return response.data.servicePackage;
        })
        .catch((error) => {
          context.dispatch(
            "setToast",
            {
              title: "Request failed!",
              type: "error",
              text: error.response?.data?.error?.message,
            },
            { root: true }
          );
        });
    },
    async updateServicePackage(
      context,
      data: { id: string; servicePackage: ServicePackage }
    ) {
      return await api
        .put(`/v1/service-package/${data.id}`, data.servicePackage)
        .then((response) => {
          context.commit("ADD_SERVICE_PACKAGE", response.data.servicePackage);
          context.dispatch(
            "setToast",
            {
              title: "Success",
              type: "success",
              text: "ServicePackage updated",
            },
            { root: true }
          );
        })
        .catch((error) => {
          context.dispatch(
            "setToast",
            {
              title: "Request failed!",
              type: "error",
              text: error.response?.data?.error?.message,
            },
            { root: true }
          );
        });
    },
    async deleteServicePackage(context, id) {
      return await api
        .delete(`/v1/service-package/${id}`, {
          headers: { "vendor-module": "services.Service Packages" },
        })
        .then((response) => {
          context.commit(
            "REMOVE_SERVICE_PACKAGE",
            response.data.servicePackage
          );
          context.dispatch(
            "setToast",
            {
              title: "Success",
              type: "success",
              text: "ServicePackage deleted",
            },
            { root: true }
          );

          return response.data.servicePackage;
        })
        .catch((error) => {
          context.dispatch(
            "setToast",
            {
              title: "Request failed!",
              type: "error",
              text: error.response?.data?.error?.message,
            },
            { root: true }
          );
        });
    },
  },
};

export default servicePackage;

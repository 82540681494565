import Vue from "vue";
import { Module } from "vuex";

import { api } from "@/util/axios";
import { Membership, Page } from "@/types";

type MembershipState = {
  membershipPage: Page<Membership>;
  membershipSessionPage: Page<any>;
};

const membership: Module<MembershipState, unknown> = {
  namespaced: true,
  state: () => ({
    membershipPage: {
      docs: [],
      limit: 0,
      page: 0,
      pages: 0,
      sort: "",
      total: 0,
    },
    membershipSessionPage: {
      docs: [],
      limit: 0,
      page: 0,
      pages: 0,
      sort: "",
      total: 0,
    },
  }),
  getters: {
    getMembership: (state) => (membershipId: string) =>
      state.membershipPage.docs.find((m) => m._id === membershipId),
    membershipPage: (state) => state.membershipPage,
    membershipSessionPage: (state) => state.membershipSessionPage,
  },
  mutations: {
    ADD_MEMBERSHIP: (state, _membership) => {
      let idx = -1;
      state.membershipPage.docs.map((r, i) => {
        if (r._id === _membership._id) idx = i;
      });
      if (idx === -1) state.membershipPage.docs.push(_membership);
      else Vue.set(state.membershipPage.docs, idx, _membership);
    },
    SET_MEMBERSHIP_PAGE: (state, list) => {
      state.membershipPage = list;
    },
    REMOVE_MEMBERSHIP(state, _membership) {
      let idx = -1;
      state.membershipPage.docs.map((r, i) => {
        if (r._id === _membership._id) idx = i;
      });
      if (idx > -1) state.membershipPage.docs.splice(idx, 1);
    },
    SET_MEMBERSHIP_SESSION: (state, _membership) => {
      state.membershipSessionPage = _membership;
    },
  },
  actions: {
    async fetchMembershipSessions(context, query = "") {
      return await api
        .get(`/v2/membership-session${query}`)
        .then((response) => {
          context.commit(
            "SET_MEMBERSHIP_SESSION",
            response.data.membershipSessionPage
          );
          console.log(response);
          return response.data.membershipSessionPage;
        })
        .catch((error) => {
          context.dispatch(
            "setToast",
            {
              title: "Request failed!",
              type: "error",
              text: error.response?.data?.error?.message,
            },
            { root: true }
          );
        });
    },
    async createMembershipSession(context, payload) {
      return await api
        .post("/v1/membership-session", { ...payload })
        .then((response) => {
          context.dispatch(
            "setToast",
            {
              title: "Success",
              type: "success",
              //text: "Session created",
            },
            { root: true }
          );
          return response.data;
        })
        .catch((error) => {
          context.dispatch(
            "setToast",
            {
              title: "Request failed!",
              type: "error",
              text: error.response?.data?.error?.message,
            },
            { root: true }
          );
        });
    },
    async verifyMembershipApply(context, payload) {
      return await api
        .post("/v1/membership-session/verify", { ...payload })
        .then((response) => {
          return response.data;
        })
        .catch((error) => {
          context.dispatch(
            "setToast",
            {
              title: "Request failed!",
              type: "error",
              text: error.response?.data?.error?.message,
            },
            { root: true }
          );
        });
    },
    async fetchMembership(context, params = "") {
      return await api
        .get(`/v1/membership${params}`)
        .then((response) => {
          context.commit("ADD_MEMBERSHIP", response.data.membership);
          return response.data.membership;
        })
        .catch((error) => {
          context.dispatch(
            "setToast",
            {
              title: "Request failed!",
              type: "error",
              text: error.response?.data?.error?.message,
            },
            { root: true }
          );
        });
    },
    fetchMembershipList(context, params = "") {
      api
        .get(`/v1/membership${params}`)
        .then((response) => {
          context.commit("SET_MEMBERSHIP_PAGE", response.data.membershipPage);
        })
        .catch((error) => {
          context.dispatch(
            "setToast",
            {
              title: "Request failed!",
              type: "error",
              text: error.response?.data?.error?.message,
            },
            { root: true }
          );
        });
    },
    async createMembership(context, payload) {
      return await api
        .post(`/v1/membership`, payload)
        .then((response) => {
          context.commit("ADD_MEMBERSHIP", response.data.membership);
          context.dispatch(
            "setToast",
            {
              title: "Success",
              type: "success",
              text: "Membership created",
            },
            { root: true }
          );
          return response.data.membership;
        })
        .catch((error) => {
          let message = error.response?.data?.error?.message as string;
          if (message.includes("E11000 duplicate key error collection"))
            message = "Membership with that name already exists";
          context.dispatch(
            "setToast",
            {
              title: "Request failed!",
              type: "error",
              text: message,
            },
            { root: true }
          );
        });
    },
    async updateMembership(
      context,
      data: { id: string; membership: Membership }
    ) {
      return await api
        .put(`/v1/membership/${data.id}`, data.membership)
        .then((response) => {
          context.commit("ADD_MEMBERSHIP", response.data.membership);
          context.dispatch(
            "setToast",
            {
              title: "Success",
              type: "success",
              text: "Membership updated",
            },
            { root: true }
          );
          return response.data.membership;
        })
        .catch((error) => {
          let message = error.response?.data?.error?.message as string;
          if (message.includes("E11000 duplicate key error collection"))
            message = "Membership with that name already exists";
          context.dispatch(
            "setToast",
            {
              title: "Request failed!",
              type: "error",
              text: message,
            },
            { root: true }
          );
        });
    },
    uploadMembershipImages(
      context,
      data: { id: string; membership: Membership }
    ) {
      api
        .put(`/v1/membership/${data.id}/images`, data.membership)
        .then((response) => {
          context.commit("ADD_MEMBERSHIP", response.data.membership);
          // context.dispatch(
          //   "setToast",
          //   {
          //     title: "Success",
          //     type: "success",
          //     text: "Membership updated",
          //   },
          //   { root: true }
          // );
        })
        .catch((error) => {
          context.dispatch(
            "setToast",
            {
              title: "Request failed!",
              type: "error",
              text: error.response?.data?.error?.message,
            },
            { root: true }
          );
        });
    },
    async uploadMembershipImage(
      context,
      data: { id: string; membership: Membership }
    ) {
      return await api
        .put(`/v1/membership/image/${data.id}`, data.membership)
        .then((response) => {
          context.commit("ADD_MEMBERSHIP", response.data.membership);
          context.dispatch(
            "setToast",
            {
              title: "Success",
              type: "success",
              text: "Membership updated",
            },
            { root: true }
          );
          return response.data.membership;
        })
        .catch((error) => {
          context.dispatch(
            "setToast",
            {
              title: "Request failed!",
              type: "error",
              text: error.response?.data?.error?.message,
            },
            { root: true }
          );
        });
    },
    async deleteMembership(context, id) {
      return await api
        .delete(`/v1/membership/${id}`)
        .then((response) => {
          context.commit("REMOVE_MEMBERSHIP", response.data.membership);
          context.dispatch(
            "setToast",
            {
              title: "Success",
              type: "success",
              text: "Membership deleted",
            },
            { root: true }
          );
          return response.data.membership;
        })
        .catch((error) => {
          context.dispatch(
            "setToast",
            {
              title: "Request failed!",
              type: "error",
              text: error.response?.data?.error?.message,
            },
            { root: true }
          );
        });
    },
  },
};

export default membership;

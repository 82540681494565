































import { ClientEmail } from "@/types";
import Vue, { PropType } from "vue";

export default Vue.extend<any, any, any, any>({
  name: "ViewEmail",
  props: {
    email: {
      type: Object as PropType<ClientEmail>,
      required: true,
    },
  },
});

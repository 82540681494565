import Vue from "vue";
import { Module } from "vuex";

import { api } from "@/util/axios";
import { _Location, Page } from "@/types";

type LocationState = {
  locationPage: Page<_Location>;
};

const location: Module<LocationState, unknown> = {
  namespaced: true,
  state: () => ({
    locationPage: {
      docs: [],
      limit: 0,
      page: 0,
      pages: 0,
      sort: "",
      total: 0,
    },
  }),
  getters: {
    getLocation: (state) => (locationId: string) =>
      state.locationPage.docs.find((c) => c._id === locationId),
    locationPage: (state) => state.locationPage,
  },
  mutations: {
    ADD_LOCATION: (state, _location) => {
      let idx = -1;
      state.locationPage.docs.map((a, i) => {
        if (a._id === _location._id) idx = i;
      });
      if (idx === -1) state.locationPage.docs.push(_location);
      else Vue.set(state.locationPage.docs, idx, _location);
    },
    SET_LOCATION_PAGE: (state, list) => {
      state.locationPage = list;
    },
    REMOVE_LOCATION(state, role) {
      let idx = -1;
      state.locationPage.docs.map((r, i) => {
        if (r._id === role._id) idx = i;
      });
      if (idx > -1) state.locationPage.docs.splice(idx, 1);
    },
  },
  actions: {
    fetchLocation(context, params = "") {
      api
        .get(`/v1/location${params}`)
        .then((response) => {
          context.commit("ADD_LOCATION", response.data.location);
        })
        .catch((error) => {
          context.dispatch(
            "setToast",
            {
              title: "Request failed!",
              type: "error",
              text: error.response?.data?.error?.message,
            },
            { root: true }
          );
        });
    },
    async fetchLocationList(context, params = "") {
      return await api
        .get(`/v1/location${params}`)
        .then((response) => {
          context.commit("SET_LOCATION_PAGE", response.data.locationPage);
          return response.data.locationPage;
        })
        .catch((error) => {
          context.dispatch(
            "setToast",
            {
              title: "Request failed!",
              type: "error",
              text: error.response?.data?.error?.message,
            },
            { root: true }
          );
        });
    },
    async createLocation(context, payload) {
      return await api
        .post(`/v1/location`, payload)
        .then((response) => {
          context.commit("ADD_LOCATION", response.data.location);
          context.dispatch(
            "setToast",
            {
              title: "Success",
              type: "success",
              text: "Location created",
            },
            { root: true }
          );
          return response.data.location;
        })
        .catch((error) => {
          context.dispatch(
            "setToast",
            {
              title: "Request failed!",
              type: "error",
              text: error.response?.data?.error?.message,
            },
            { root: true }
          );
        });
    },
    async updateLocation(context, data: { id: string; location: Location }) {
      return await api
        .put(`/v1/location/${data.id}`, data.location)
        .then((response) => {
          context.commit("ADD_LOCATION", response.data.location);
          context.dispatch(
            "setToast",
            {
              title: "Success",
              type: "success",
              text: "Location updated",
            },
            { root: true }
          );
          return response.data.location;
        })
        .catch((error) => {
          context.dispatch(
            "setToast",
            {
              title: "Request failed!",
              type: "error",
              text: error.response?.data?.error?.message,
            },
            { root: true }
          );
        });
    },
    async deleteLocation(context, params) {
      return await api
        .delete(`/v1/location/${params}`, {
          headers: { "vendor-module": "settings.Location" },
        })
        .then((response) => {
          context.commit("REMOVE_LOCATION", response.data.location);
          context.dispatch(
            "setToast",
            {
              title: "Success",
              type: "success",
              text: "Location deleted",
            },
            { root: true }
          );
          return response.data.location;
        })
        .catch((error) => {
          context.dispatch(
            "setToast",
            {
              title: "Request failed!",
              type: "error",
              text: error.response?.data?.error?.message,
            },
            { root: true }
          );
        });
    },
  },
};

export default location;

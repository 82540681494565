import Vue from "vue";
import { Module } from "vuex";

import { api } from "@/util/axios";
import { ServiceCategory, Page } from "@/types";

type ServiceCategoryState = {
  serviceCategoryPage: Page<ServiceCategory>;
};

const serviceCategory: Module<ServiceCategoryState, unknown> = {
  namespaced: true,
  state: () => ({
    serviceCategoryPage: {
      docs: [],
      limit: 0,
      page: 0,
      pages: 0,
      sort: "",
      total: 0,
    },
  }),
  getters: {
    getServiceCategory: (state) => (serviceCategoryId: string) =>
      state.serviceCategoryPage.docs.find((c) => c._id === serviceCategoryId),
    serviceCategoryPage: (state) => state.serviceCategoryPage,
  },
  mutations: {
    ADD_SERVICE_CATEGORY: (state, _serviceCategory) => {
      let idx = -1;
      state.serviceCategoryPage.docs.map((a, i) => {
        if (a._id === _serviceCategory._id) idx = i;
      });
      if (idx === -1) state.serviceCategoryPage.docs.push(_serviceCategory);
      else Vue.set(state.serviceCategoryPage.docs, idx, _serviceCategory);
    },
    SET_SERVICE_CATEGORY_PAGE: (state, list) => {
      state.serviceCategoryPage = list;
    },
    REMOVE_SERVICE_CATEGORY(state, serviceCategory) {
      let idx = -1;
      state.serviceCategoryPage.docs.map((a, i) => {
        if (a._id === serviceCategory._id) idx = i;
      });
      if (idx > -1) state.serviceCategoryPage.docs.splice(idx, 1);
    },
  },
  actions: {
    fetchServiceCategory(context, params = "") {
      api
        .get(`/v1/service-category${params}`)
        .then((response) => {
          context.commit("ADD_SERVICE_CATEGORY", response.data.serviceCategory);
        })
        .catch((error) => {
          context.dispatch(
            "setToast",
            {
              title: "Request failed!",
              type: "error",
              text: error.response?.data?.error?.message,
            },
            { root: true }
          );
        });
    },
    async fetchServiceCategoryList(context, params = "") {
      return await api
        .get(`/v1/service-category${params}`)
        .then((response) => {
          context.commit(
            "SET_SERVICE_CATEGORY_PAGE",
            response.data.serviceCategoryPage
          );
          return response.data.serviceCategoryPage;
        })
        .catch((error) => {
          context.dispatch(
            "setToast",
            {
              title: "Request failed!",
              type: "error",
              text: error.response?.data?.error?.message,
            },
            { root: true }
          );
        });
    },
    async createServiceCategory(context, payload) {
      return await api
        .post(`/v1/service-category`, payload)
        .then((response) => {
          context.commit("ADD_SERVICE_CATEGORY", response.data.serviceCategory);
          context.dispatch(
            "setToast",
            {
              title: "Success",
              type: "success",
              text: "ServiceCategory created",
            },
            { root: true }
          );
          return response.data.serviceCategory;
        })
        .catch((error) => {
          context.dispatch(
            "setToast",
            {
              title: "Request failed!",
              type: "error",
              text: error.response?.data?.error?.message,
            },
            { root: true }
          );
        });
    },
    async updateServiceCategory(
      context,
      data: { id: string; serviceCategory: ServiceCategory }
    ) {
      return await api
        .put(`/v1/service-category/${data.id}`, data.serviceCategory)
        .then((response) => {
          context.commit("ADD_SERVICE_CATEGORY", response.data.serviceCategory);
          context.dispatch(
            "setToast",
            {
              title: "Success",
              type: "success",
              text: "ServiceCategory updated",
            },
            { root: true }
          );
          return response.data.serviceCategory;
        })
        .catch((error) => {
          context.dispatch(
            "setToast",
            {
              title: "Request failed!",
              type: "error",
              text: error.response?.data?.error?.message,
            },
            { root: true }
          );
        });
    },
    async deleteServiceCategory(context, id) {
      return await api
        .delete(`/v1/service-category/${id}`, {
          headers: { "vendor-module": "services.Category" },
        })
        .then((response) => {
          context.commit(
            "REMOVE_SERVICE_CATEGORY",
            response.data.serviceCategory
          );
          context.dispatch(
            "setToast",
            {
              title: "Success",
              type: "success",
              text: "ServiceCategory deleted",
            },
            { root: true }
          );
          return response.data.serviceCategory;
        })
        .catch((error) => {
          context.dispatch(
            "setToast",
            {
              title: "Request failed!",
              type: "error",
              text: error.response?.data?.error?.message,
            },
            { root: true }
          );
        });
    },
    async exportServices(context, params = "") {
      return await api
        .get(`/v1/service/export${params}`)
        .then((response) => response)
        .catch((error) => {
          context.dispatch(
            "setToast",
            {
              title: "Request failed!",
              type: "error",
              text: error.response?.data?.error?.message,
            },
            { root: true }
          );
        });
    },
  },
};

export default serviceCategory;

import Vue from "vue";
import { Module } from "vuex";

import { Cart, Page } from "@/types";

type CartState = {
  cartPage: Page<Cart>;
  currentLocation: string;
};

const checkout: Module<CartState, unknown> = {
  namespaced: true,
  state: () => ({
    cartPage: {
      docs: [],
      limit: 0,
      page: 0,
      pages: 0,
      sort: "",
      total: 0,
    },
    currentLocation: "",
  }),
  getters: {
    getCart: (state) => (employeeId: string) =>
      state.cartPage.docs.find((c) => c._id === employeeId),
    cartPage: (state) => state.cartPage,
    currentLocation: (state) => state.currentLocation,
  },
  mutations: {
    ADD_CART: (state, _cart) => {
      let idx = -1;
      state.cartPage.docs.map((a, i) => {
        if (a._id === _cart._id) idx = i;
      });
      if (idx === -1) {
        state.cartPage.docs = [];
        state.cartPage.docs.push(..._cart);
        console.log("cartPage,", state.cartPage.docs);
      } else Vue.set(state.cartPage.docs, idx, _cart);
    },
    SET_CART_PAGE: (state, list) => {
      state.cartPage = list;
    },

    ADD_LOCATION: (state, loc) => {
      state.currentLocation = loc;
    },
  },
  actions: {
    async addToCart(context, payload) {
      context.commit("ADD_CART", payload);
      console.log("payload", payload);
      localStorage.setItem("cart_data", JSON.stringify(payload));
    },

    async setLocation(context, payload) {
      context.commit("ADD_LOCATION", payload);
      localStorage.setItem("location", payload);
    },

    async deleteItemToCart(context, payload) {
      context.commit("ADD_CART", payload);
    },

    async updateCart(context) {
      const dfs = localStorage.getItem("cart_data");
      context.commit("ADD_CART", dfs);
    },
  },
};

export default checkout;

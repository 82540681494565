import Vue from "vue";
import { Module } from "vuex";

import { api } from "@/util/axios";
import { Service, Page } from "@/types";

export interface ServiceState {
  servicePage: Page<Service>;
}

interface RootSate {
  toast: any;
  loadingRequest: boolean;
}

const service: Module<ServiceState, RootSate> = {
  namespaced: true,
  state: () => ({
    servicePage: {
      docs: [],
      limit: 0,
      page: 0,
      pages: 0,
      sort: "",
      total: 0,
    },
  }),
  getters: {
    getService: (state) => (serviceId: string) =>
      state.servicePage.docs.find((c) => c._id === serviceId),
    servicePage: (state) => state.servicePage,
  },
  mutations: {
    ADD_SERVICE: (state, _service) => {
      let idx = -1;
      state.servicePage.docs.map((a, i) => {
        if (a._id === _service._id) idx = i;
      });
      if (idx === -1) state.servicePage.docs.push(_service);
      else Vue.set(state.servicePage.docs, idx, _service);
    },
    SET_SERVICE_PAGE: (state, list) => {
      state.servicePage = list;
    },
    REMOVE_SERVICE(state, service) {
      let idx = -1;
      state.servicePage.docs.map((a, i) => {
        if (a._id === service._id) idx = i;
      });
      if (idx > -1) state.servicePage.docs.splice(idx, 1);
    },
  },
  actions: {
    async fetchService(context, params = "") {
      return await api
        .get(`/v1/service${params}`)
        .then((response) => {
          context.commit("ADD_SERVICE", response.data.service);
          return response.data.service;
        })
        .catch((error) => {
          context.dispatch(
            "setToast",
            {
              title: "Request failed!",
              type: "error",
              text: error.response?.data?.error?.message,
            },
            { root: true }
          );
        });
    },
    async fetchServiceList(context, params = "") {
      return await api
        .get(`/v1/service${params}`)
        .then((response) => {
          context.commit("SET_SERVICE_PAGE", response.data.servicePage);
          return response.data.servicePage;
        })
        .catch((error) => {
          context.dispatch(
            "setToast",
            {
              title: "Request failed!",
              type: "error",
              text: error.response?.data?.error?.message,
            },
            { root: true }
          );
        });
    },
    async createService(context, payload) {
      return await api
        .post(`/v1/service`, payload)
        .then((response) => {
          context.commit("ADD_SERVICE", response.data.service);
          context.dispatch(
            "setToast",
            {
              title: "Success",
              type: "success",
              text: "Service created",
            },
            { root: true }
          );
          return response.data.service;
        })
        .catch((error) => {
          context.dispatch(
            "setToast",
            {
              title: "Request failed!",
              type: "error",
              text: error.response?.data?.error?.message,
            },
            { root: true }
          );
        });
    },
    async updateService(context, data: { id: string; service: Service }) {
      return await api
        .put(`/v1/service/${data.id}`, data.service)
        .then((response) => {
          context.commit("ADD_SERVICE", response.data.service);
          context.dispatch(
            "setToast",
            {
              title: "Success",
              type: "success",
              text: "Service updated",
            },
            { root: true }
          );
          return response.data.service;
        })
        .catch((error) => {
          context.dispatch(
            "setToast",
            {
              title: "Request failed!",
              type: "error",
              text: error.response?.data?.error?.message,
            },
            { root: true }
          );
        });
    },
    async bulkUpdateServices(context, data) {
      return await api
        .put(`/v1/service/bulk/`, data)
        .then((response) => {
          response.data.services.map((s: Service) =>
            context.commit("ADD_SERVICE", s)
          );
          context.dispatch(
            "setToast",
            {
              title: "Success",
              type: "success",
              text: "Service updated",
            },
            { root: true }
          );
          return response.data.services;
        })
        .catch((error) => {
          context.dispatch(
            "setToast",
            {
              title: "Request failed!",
              type: "error",
              text: error.response?.data?.error?.message,
            },
            { root: true }
          );
        });
    },
    async uploadServiceImage(context, data: { id: string; service: Service }) {
      return await api
        .put(`/v1/service/image/${data.id}`, data.service)
        .then((response) => {
          context.commit("ADD_SERVICE", response.data.service);
          context.dispatch(
            "setToast",
            {
              title: "Success",
              type: "success",
              text: "Service updated",
            },
            { root: true }
          );
          return response.data.service;
        })
        .catch((error) => {
          context.dispatch(
            "setToast",
            {
              title: "Request failed!",
              type: "error",
              text: error.response?.data?.error?.message,
            },
            { root: true }
          );
        });
    },
    deleteService(context, id) {
      api
        .delete(`/v1/service${id}`, {
          headers: { "vendor-module": "services.All Services" },
        })
        .then((response) => {
          context.commit("REMOVE_SERVICE", response.data.service);
          context.dispatch(
            "setToast",
            {
              title: "Success",
              type: "success",
              text: "Service deleted",
            },
            { root: true }
          );
        })
        .catch((error) => {
          context.dispatch(
            "setToast",
            {
              title: "Request failed!",
              type: "error",
              text: error.response?.data?.error?.message,
            },
            { root: true }
          );
        });
    },
    bulkUpdateServicePerformers(context, data) {
      api
        .put(`/v1/service/bulk/performer`, data)
        .then((response) => {
          response.data.services.map((s: Service) => {
            context.commit("ADD_SERVICE", s);
          });
        })
        .catch((error) => {
          context.dispatch(
            "setToast",
            {
              title: "Request failed!",
              type: "error",
              text: error.response?.data?.error?.message,
            },
            { root: true }
          );
        });
    },
  },
};

export default service;

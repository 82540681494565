import { Module } from "vuex";

import { Page, ClientConsultationForm } from "@/types";
import { api } from "@/util/axios";
import Vue from "vue";

type clientConsultationState = {
  clientConsultationPage: Page<ClientConsultationForm>;
};

const clientConsultation: Module<clientConsultationState, unknown> = {
  namespaced: true,
  state: () => ({
    clientConsultationPage: {
      docs: [],
      limit: 0,
      page: 0,
      pages: 0,
      sort: "",
      total: 0,
    },
  }),
  getters: {
    clientConsultationPage: (state) => state.clientConsultationPage,
    getConsultationForm: (state) => (formId: string) =>
      state.clientConsultationPage.docs.find((form) => form._id === formId),
  },
  mutations: {
    ADD_CLIENT_CONSULTATIONFORM: (state, _form) => {
      let idx = -1;
      state.clientConsultationPage.docs.map((r, i) => {
        if (r._id === _form._id) idx = i;
      });
      if (idx === -1) state.clientConsultationPage.docs.push(_form);
      else Vue.set(state.clientConsultationPage.docs, idx, _form);
    },
    SET_CLIENT_CONSULTATIONFORM_PAGE: (state, list) => {
      state.clientConsultationPage = list;
    },
  },
  actions: {
    fetchClientConsultationList(context, params = "") {
      api
        .get(`/v1/consultation-form${params}`)
        .then((response) => {
          context.commit(
            "SET_CLIENT_CONSULTATIONFORM_PAGE",
            response.data.consultationFormPage
          );
        })
        .catch((error) => {
          context.dispatch(
            "setToast",
            {
              title: "Request failed!",
              type: "error",
              text: error.response?.data?.error?.message,
            },
            { root: true }
          );
        });
    },
    async fetchClientConsultationForm(context, params = "") {
      return await api
        .get(`/v1/consultation-form${params}`)
        .then((response) => {
          context.commit(
            "ADD_CLIENT_CONSULTATIONFORM",
            response.data.consultationForm
          );
          return response.data.consultationForm;
        })
        .catch((error) => {
          context.dispatch(
            "setToast",
            {
              title: "Request failed!",
              type: "error",
              text: error.response?.data?.error?.message,
            },
            { root: true }
          );
        });
    },
    async createClientConsultationForm(context, payload) {
      return await api
        .post(`v1/consultation-form`, payload)
        .then((response) => {
          context.commit(
            "ADD_CLIENT_CONSULTATIONFORM",
            response.data.consultationForm
          );
          context.dispatch(
            "setToast",
            {
              title: "Success",
              type: "success",
              text: "Client Consultation Form created",
            },
            { root: true }
          );
          return response.data.consultationForm;
        })
        .catch((error) => {
          console.log(error);
          context.dispatch(
            "setToast",
            {
              title: "Request failed!",
              type: "error",
              text: error.response?.data?.error?.message,
            },
            { root: true }
          );
        });
    },
    async updateClientConsultationForm(context, payload) {
      return await api
        .put(`v1/consultation-form`, payload)
        .then((res) => {
          context.dispatch(
            "setToast",
            {
              title: "Success",
              type: "success",
              text: "Client Consultation Form created",
            },
            { root: true }
          );
          return res.data.consultationForm;
        })
        .catch((error) => {
          console.log(error);
          context.dispatch(
            "setToast",
            {
              title: "Request failed!",
              type: "error",
              text: error.response?.data?.error?.message,
            },
            { root: true }
          );
        });
    },
    async deleteClientConsultationForm(context, id) {
      return await api
        .delete(`v1/consultation-form/${id}`, {
          headers: { "vendor-module": "clients.Consultation forms" },
        })
        .then((response) => {
          context.commit("REMOVE_FORM", response.data.consultationForm);
          context.dispatch(
            "setToast",
            {
              title: "Success",
              type: "success",
              text: "Consultation Form deleted",
            },
            { root: true }
          );
          return response.data.consultationForm;
        })
        .catch((error) => {
          context.dispatch(
            "setToast",
            {
              title: "Request failed!",
              type: "error",
              text: error.response?.data?.error?.message,
            },
            { root: true }
          );
        });
    },
  },
};

export default clientConsultation;

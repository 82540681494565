var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"px-5"},[_c('v-dialog',{attrs:{"eager":"","width":"540"},model:{value:(_vm.addEmailDialog),callback:function ($$v) {_vm.addEmailDialog=$$v},expression:"addEmailDialog"}},[(_vm.addEmailDialog)?_c('client-email-form',{attrs:{"client":_vm.client},on:{"data-saved":_vm.closeModal,"cancel":function($event){_vm.addEmailDialog = false}}}):_vm._e()],1),_c('v-dialog',{attrs:{"eager":"","width":"540"},model:{value:(_vm.showEmailDialog),callback:function ($$v) {_vm.showEmailDialog=$$v},expression:"showEmailDialog"}},[(_vm.showEmailDialog)?_c('view-email',{attrs:{"email":_vm.email},on:{"close":function($event){_vm.showEmailDialog = false}}}):_vm._e()],1),(_vm.showActions)?_c('action-buttons'):_vm._e(),_c('v-row',[_c('v-col',{staticStyle:{"text-align":"right"}},[_c('v-btn',{attrs:{"text":""},on:{"click":function($event){_vm.addEmailDialog = true}}},[_c('v-icon',[_vm._v("mdi-send")]),_vm._v(" Send New Email ")],1)],1)],1),_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.clientEmailPage.docs,"options":_vm.options,"server-items-length":_vm.clientEmailPage.total,"no-data-text":"No emails to display"},on:{"update:options":function($event){_vm.options=$event}},scopedSlots:_vm._u([{key:"item.client",fn:function(ref){
var item = ref.item;
return [_c('div',{staticStyle:{"display":"flex","justify-content":"flex-start","align-items":"center"}},[(item.avatar)?_c('v-avatar',[_c('img',{attrs:{"src":(_vm.apiUrl + "/v1/file/" + (item.client.avatar.filename)),"alt":""}})]):_c('v-avatar',[_c('img',{attrs:{"src":require("../../assets/avatar.png"),"alt":item.client.fullName}})]),_c('span',{staticStyle:{"white-space":"initial","margin-left":"5px"}},[_vm._v(" "+_vm._s(item.client.fullName)+" ")])],1)]}},{key:"item.createdAt",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("readableDate")(item.createdAt,"DD/MM/YYYY"))+" ")]}},{key:"item.action",fn:function(ref){
var item = ref.item;
return [_c('v-btn',{attrs:{"color":"primary","rounded":""},on:{"click":function($event){_vm.email = item;
          _vm.showEmailDialog = true;}}},[_vm._v(" View ")])]}}],null,true)})],1)}
var staticRenderFns = []

export { render, staticRenderFns }
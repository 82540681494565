import Vue from "vue";
import { Module } from "vuex";

import { api } from "@/util/axios";
import { User, Page } from "@/types";

type UserState = {
  userPage: Page<User>;
};

const user: Module<UserState, unknown> = {
  namespaced: true,
  state: () => ({
    userPage: {
      docs: [],
      limit: 0,
      page: 0,
      pages: 0,
      sort: "",
      total: 0,
    },
    userCount: 0,
    userAggregate: [],
    users: [],
  }),
  getters: {
    getUser: (state) => (userId: string) =>
      state.userPage.docs.find((c) => c._id === userId),
    userPage: (state) => state.userPage,
  },
  mutations: {
    ADD_USER: (state, _user) => {
      let idx = -1;
      state.userPage.docs.map((a, i) => {
        if (a._id === _user._id) idx = i;
      });
      if (idx === -1) state.userPage.docs.push(_user);
      else Vue.set(state.userPage.docs, idx, _user);
    },
    SET_USER_PAGE: (state, list) => {
      state.userPage = list;
    },
    REMOVE_USER(state, _user) {
      let idx = -1;
      state.userPage.docs.map((a, i) => {
        if (a._id === _user._id) idx = i;
      });
      if (idx > -1) state.userPage.docs.splice(idx, 1);
    },
  },
  actions: {
    async fetchUser(context, params = "") {
      return await api
        .get(`/v1/user${params}`)
        .then((response) => {
          context.commit("ADD_USER", response.data.user);
          return response.data.user;
        })
        .catch((error) => {
          context.dispatch(
            "setToast",
            {
              title: "Request failed!",
              type: "error",
              text: error.response?.data?.error?.message,
            },
            { root: true }
          );
        });
    },
    fetchUserList(context, params = "") {
      api
        .get(`/v1/user${params}`)
        .then((response) => {
          context.commit("SET_USER_PAGE", response.data.userPage);
        })
        .catch((error) => {
          context.dispatch(
            "setToast",
            {
              title: "Request failed!",
              type: "error",
              text: error.response?.data?.error?.message,
            },
            { root: true }
          );
        });
    },
  },
};

export default user;

























































import Vue, { PropType } from "vue";
import { createNamespacedHelpers } from "vuex";

import clientSMSStoreModule from "@/store/modules/clientSMS";
import clientStoreModule from "@/store/modules/client";
import { Business, Client, Role } from "@/types";

const { mapActions: clientActions, mapGetters: clientGetters } =
  createNamespacedHelpers("CLIENT_SMSS");

const { mapActions: smsActions } = createNamespacedHelpers("CLIENT_SMS_FORM");

export default Vue.extend<any, any, any, any>({
  name: "ClientSMSForm",
  props: {
    client: {
      type: Object as PropType<Client>,
      required: false,
    },
  },
  data() {
    return {
      valid: false,
      clientIds: [] as string[],
      message: "",
      messageRules: [(v: string) => !!v || "Message field is required"],
      clientRules: [
        (v: string) => !!(v && v.length) || "Client field is required",
      ],
    };
  },
  mounted() {
    this.fetchData();
    this.resetForm();
  },
  computed: {
    ...clientGetters(["clientPage"]),
    role(): Role {
      return this.$store.getters.role;
    },
  },
  methods: {
    ...smsActions(["createClientSMS"]),
    ...clientActions(["fetchClientList"]),
    validateForm() {
      const valid = (
        this.$refs.smsForm as Element & {
          validate: () => boolean;
        }
      )?.validate();
      if (!valid) return;
      // Logic for sending smss

      this.createClientSMS({
        message: this.message,
        clientIds: this.clientIds,
        businessId: (this.role.business as Business)._id,
      }).then((smss) => {
        if (smss) this.$emit("data-saved", true);
      });
    },
    resetForm() {
      this.clientIds = [];
      if (this.client) this.clientIds.push(this.client._id);
      this.message = "";
      (
        this.$refs.smsForm as Element & {
          resetValidation: () => void;
        }
      )?.resetValidation();
    },
    cancel() {
      this.$emit("cancel", true);
    },
    fetchData() {
      if (this.role) {
        const bid = (this.role.business as Business)._id;
        this.fetchClientList(`?businessId=${bid}`);
      }
    },
    searchClient(q: string) {
      if (this.role) {
        const bid = (this.role.business as Business)._id;
        if (q) this.fetchClientList(`?businessId=${bid}&q=${q}`); //TODO: use debounce
      }
    },
    addClient() {
      this.$emit("add-client", true);
    },
  },
  beforeCreate() {
    if (!this.$store.hasModule("CLIENT_SMS_FORM")) {
      this.$store.registerModule("CLIENT_SMS_FORM", clientSMSStoreModule);
    }
    if (!this.$store.hasModule("CLIENT_SMSS")) {
      this.$store.registerModule("CLIENT_SMSS", clientStoreModule);
    }
  },
  beforeDestroy() {
    this.$store.unregisterModule("CLIENT_SMS_FORM");
  },
});
